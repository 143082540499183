/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Layout from 'components/layout/Layout'
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import Levenshtein from 'js-levenshtein'

const NotFound = styled.div`
  & h1 {
    font-size: 100px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.secondary};
  }

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & p {
    & b,
    strong {
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  & a {
    &:hover {
      text-decoration: underline;
    }
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const Links = styled.div`
  & p {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const BackToHome = styled.div`
  & a {
    text-decoration: underline;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    transition: all 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }
`

const Or = styled.div`
  padding: 10px 0;
  color: ${({ theme }) => theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

interface Props {
  location: any
}

const WhiteBlock = styled.div`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 10px 30px;
`

const NotFoundPage: React.FC<Props> = ({ location }) => {
  const query = useStaticQuery<GatsbyTypes.notfoundQuery>(graphql`
    query notfound {
      allWpPage {
        nodes {
          title
          uri
        }
      }
    }
  `)

  const [list, setList] = React.useState([])

  React.useEffect(() => {
    const levenshteinList: any = []

    query.allWpPage.nodes.forEach((n: any) => {
      levenshteinList.push({
        uri: n.uri,
        title: n.title,
        score: Levenshtein(location.pathname, n.uri),
      })
    })

    setList(levenshteinList)
  }, [])

  return (
    <Layout>
      <div className="container py-lg-5 py-3">
        <NotFound className="row justify-content-center">
          <div className="col-lg-8 pb-lg-5 pb-3">
            <WhiteBlock>
              <h1>Oeps!</h1>
              <h3>Het lijkt erop dat deze pagina niet (meer) bestaat.</h3>
              <div className="py-3">
                <p className="mb-0">
                  Error code: <b>404</b>
                </p>
              </div>
            </WhiteBlock>
          </div>
          <Links className="col-lg-9">
            <BackToHome>
              <NavLink to="/">Ga terug naar de homepage</NavLink>
            </BackToHome>
            <Or>OF</Or>
            <p className="mb-0">
              hier zijn een paar paginas die misschien op jouw pagina lijken:
            </p>
            <Block>
              {list
                .sort((a: any, b: any) => a.score - b.score)
                .map((n: any, index: number) => (
                  <div key={index}>
                    <NavLink to={n.uri}>{n.title}</NavLink>
                  </div>
                ))}
            </Block>
          </Links>
        </NotFound>
      </div>
    </Layout>
  )
}

export default NotFoundPage
